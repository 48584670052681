<template>
  <div class="body">
    <router-view></router-view>

  </div>
</template>

<script>
export default {
}
</script>

<!-- 
<style scoped lang="css" src="@/nmra/partials/style.css" /> -->