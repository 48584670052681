<template>
  <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
    <form class="" @submit.prevent="handleSubmit(save)">
      <div class="row">
        <div class="col-md-6">
          <base-input label="Username:" type="text" readonly v-model="username"></base-input>
        </div>

        <div class="col-md-4">
          <base-input label="Password:" :type="passwordFieldType" v-model="password"></base-input>
        </div>
        <div class="col-md-1 password-eye" style="margin-top: 40px">
          <span @click.prevent="switchVisibility"
            ><i class="fa fa-eye"></i
          ></span>
        </div>
        <div class="col-md-12 mt-3 text-center">
          <base-button class="" native-type="submit">
            Submit
          </base-button>
        </div>
      </div>
    </form>
  </validation-observer>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "change-password",
  props: {
    userId: Number,
  },
  data() {
    return {
      password: "",
      username: "",
      userid: "",
      passwordFieldType: "password",
    };
  },

  created() {
    this.$http.get("user/employee_data").then((resp) => {
      this.user_id = resp.data[0].id;
      this.username = resp.data[0].user_name;
      this.password = resp.data[0].access_code;
    });
  },

  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    save() {
      this.$http
        .post("user/update_password", {
          user_id: this.user_id,
          username: this.username,
          password: this.password,
        })
        .then((resp) => {
          Swal.fire({
            text: "Password Changed Successfully.",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
                console.log(result.value);
                this.$emit("closeChangePasswordPop");
             // this.$router.go(this.$route.path);
            }
          });
        });
    },
  },
};
</script>
<style scoped>
.password-eye span {
  border: 1px solid #808080b3;
  padding: 8px;
  border-radius: 5px;
  background: #80808029;
}
</style>
